import React from "react"
import Component from "./component"
import fixtures from "./fixtures"

const CovidCallout = () => {
  return <Component {...fixtures.default} />
}

export default CovidCallout

export { Component as component, fixtures }
