import React from "react"
import PropTypes from "prop-types"

import { Parallax, Background } from "react-parallax"
import { StaticImage } from "gatsby-plugin-image"

import Animation from "@src/components/animation"
import SVG from "@src/components/svg/component"

import styles from "./styles.module.scss"

const CovidCallout = ({ heading }) => {
  const file = "../../../../../static/uploads/adventurous-life/"

  return (
    <div className={styles.covid_callout}>
      <Parallax contentClassName={styles.content} strength={100}>
        <Background className={styles.background_image}>
          <StaticImage
            alt="Don't Panic"
            placeholder="blurred"
            src={`${file}01--dont-panic--bg@2x.jpg`}
            width={1440}
          />
        </Background>
        <Animation fade="up" tag="h1">
          {heading}
        </Animation>
      </Parallax>

      <div className={styles.arrow_container}>
        <Animation fade="down">
          <span>Hello COVID</span>
          <SVG name="arrow-down-wide" />
        </Animation>
      </div>
    </div>
  )
}

CovidCallout.propTypes = {
  heading: PropTypes.string
}

CovidCallout.defaultProps = {}

export default CovidCallout
